.chat_scroller {
    padding-right: 0;
    padding-left: 0;
    scroll-behavior: smooth;
    max-height: calc(100dvh - 230px) !important;
    height: calc(100dvh - 230px) !important;
    position: relative;
    z-index: 1;
    overflow-y: auto;
    float: left;
    padding-bottom: 5px;
    margin-top: 0;
    width: 100%
}

.inner-chat {
    width: 100%;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
}


@-webkit-keyframes lds-ellipsis1{0%{-webkit-transform:scale(0);transform:scale(0)}to{-webkit-transform:scale(1);transform:scale(1)}}@keyframes lds-ellipsis1{0%{-webkit-transform:scale(0);transform:scale(0)}to{-webkit-transform:scale(1);transform:scale(1)}}@-webkit-keyframes lds-ellipsis3{0%{-webkit-transform:scale(1);transform:scale(1)}to{-webkit-transform:scale(0);transform:scale(0)}}@keyframes lds-ellipsis3{0%{-webkit-transform:scale(1);transform:scale(1)}to{-webkit-transform:scale(0);transform:scale(0)}}@-webkit-keyframes lds-ellipsis2{0%{-webkit-transform:translate(0,0);transform:translate(0,0)}to{-webkit-transform:translate(24px,0);transform:translate(24px,0)}}@keyframes lds-ellipsis2{0%{-webkit-transform:translate(0,0);transform:translate(0,0)}to{-webkit-transform:translate(24px,0);transform:translate(24px,0)}}

.lds-ellipsis{display:inline-block;position:relative;width:80px;height:40px}
.lds-ellipsis div{position:absolute;top:16px;width:13px;height:13px;border-radius:50%;background:#737b8f;-webkit-animation-timing-function:cubic-bezier(0,1,1,0);animation-timing-function:cubic-bezier(0,1,1,0)}
.lds-ellipsis div:nth-child(1){left:8px;-webkit-animation:lds-ellipsis1 1.3s infinite;animation:lds-ellipsis1 1.3s infinite}.lds-ellipsis div:nth-child(2),.lds-ellipsis div:nth-child(3){left:8px;-webkit-animation:lds-ellipsis2 1.3s infinite;animation:lds-ellipsis2 1.3s infinite}
.lds-ellipsis div:nth-child(3){left:32px}
.lds-ellipsis div:nth-child(4){left:56px;-webkit-animation:lds-ellipsis3 1.3s infinite;animation:lds-ellipsis3 1.3s infinite}


ul {
    list-style: none;
    border-radius: 5px;
    padding: 10px;
}
li {
    font-size: 1.2em;
    padding: 10px;
    background: rgba(0, 0, 0, 0.02);
}


p,
span {
    font-size: 1.2rem;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0px rgb(218, 18, 18);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
}

.optionHeaderBox {
    border: 1px solid #ccc;


    border-radius: 40px;
    margin: 0 auto;
    width: 120px;
    font-weight: bold;
    text-align: center;
    padding: 5px
}

@keyframes pulse {
    from {background-color: orangered;}
    to {background-color: #999;}
}

.languageSelectorBox {
    position: absolute;
    width: 150px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #f7f7f7;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    top: 0;
    z-index: 1000;
    border-radius: 0 0 5px 5px;
    font-size: .75rem;
}

.micSelectorBox {
    position: absolute;
    width: 30px;
    right: 0;
    margin: auto;
    background-color: #f7f7f7;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    top: 0;
    z-index: 1000;
    border-radius: 0 0 5px 5px;
    font-size: .8rem;
}


.scrollButtonBox {
    position: absolute;
    width: 36px;
    right: 25px;
    background-color: #333;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-top: 0;
    top: 0;
    z-index: 1000;
    border-radius: 0 0 5px 5px;
}


.submissionsContainer {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    justify-content: space-between;
}


.submissionSubcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
